import React from "react";
import { Link } from "gatsby";

const isHash = str => /^#/.test(str);
const isInternal = to => /^\/(?!\/)/.test(to);

// Only use <Link /> for internal links
const MdxLink = ({ href, ...props }) =>
  isHash(href) || !isInternal(href) ? (
    <a {...props} href={href} />
  ) : (
    <Link {...props} to={href} />
  );

export default MdxLink;
