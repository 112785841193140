import React from "react";
import { graphql } from "gatsby";
import { MDXProvider } from "@mdx-js/react";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { jsx } from "@emotion/react";
import styled from "@emotion/styled";
import MdxLink from "../components/mdxLink";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { mq } from "../utils/helper";
import { colors } from "../style/theme.js";
import { scale, rhythm } from "../utils/typography";
import { Disqus } from "gatsby-plugin-disqus";

const postStyle = {
  A: styled(MdxLink)({
    color: colors.green,
    textDecoration: "none",
    "a:hover,a:active": {
      color: "black",
      backgroundColor: colors.green,
    },
    "a:visited, a:link": {
      color: colors.green,
      textDecoration: "none",
    },
  }),
  P: styled.p({
    ...scale(0.1),
  }),
};

const BlogPostTemplate = ({ data, location }) => {
  const post = data.mdx;
  const isBlogPost = post.frontmatter.isBlog;
  const image = post.frontmatter.image;

  const disqusConfig = {
    url: `https://elfehrest.com${location.pathname}`,
    identifier: post.id,
    title: post.frontmatter.title,
  };

  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <article
        css={{
          boxShadow: "1px 2px 15px #e5e5e5",
          margin: "1.5rem auto",
          backgroundColor: colors.light,
          width: "100%",
          padding: "1rem",
          [mq[0]]: {
            padding: "1rem",
          },
        }}
      >
        {isBlogPost && (
          <img
            src={image.childImageSharp.fluid.src}
            alt={post.frontmatter.title}
          />
        )}

        <h2
          css={{
            textAlign: "center",
            lineHeight: 1.6,
            [mq[0]]: {
              fontSize: "2rem",
            },
          }}
        >
          {post.frontmatter.title}
        </h2>
        <div />
        <MDXProvider
          components={{
            a: postStyle.A,
            p: postStyle.P,
          }}
        >
          <MDXRenderer>{post.body}</MDXRenderer>
        </MDXProvider>
      </article>
      {isBlogPost && (
        <div
          css={{
            backgroundColor: colors.white,
            padding: rhythm(1),
            marginBottom: rhythm(1),
          }}
        >
          <h3>التعليقات</h3>
          <Disqus config={disqusConfig} />
        </div>
      )}
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query oldBlogPost($path: String!) {
    mdx(frontmatter: { path: { eq: $path } }) {
      id
      body
      frontmatter {
        title
        date
        isBlog
        path
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
